import { defineComponent, watch, ref, computed, onMounted } from '@vue/composition-api';
import { cloneDeep } from 'lodash';
import FormCheckbox from '@/shared/components/form/FormCheckbox.vue';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import PageLoadingBar from '@/shared/components/PageLoadingBar.vue';
import toJSON from '@/api/base/toJSON';
import getFeatures from '@/api/space/getFeatures';
import { lnManager } from '@/i18n/lnManager';
import useAsyncPage from '@/shared/composables/useAsyncPage';
import InputHeader from '@/components/InputHeader.vue';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
    components: {
        InputHeader,
        FormCheckbox,
        PageLoadingBar
    },
    props: {
        revert: {
            type: Boolean,
            default: false
        },
        onSave: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const form = ref({});
        const formLoaded = ref({});
        const isSaving = ref(false);
        const venueId = ref(null);
        const toast = useToast();
        const spaceDetails = computed(() => globalThis.$store.getters['$_vendor/spaceDetails']);
        const spaceStaticFeatures = computed(() => globalThis.$store.getters['$_vendor/spaceStaticFeatures']);
        watch(() => spaceDetails.value, () => {
            if (spaceDetails.value) {
                form.value = spaceDetails.value;
            }
        }, { immediate: true });
        watch(() => props.revert, () => {
            if (props.revert) {
                form.value.features = cloneDeep(formLoaded.value);
            }
        }, { immediate: true });
        const dataFetch = async () => {
            formLoaded.value = {};
            const { spaceId, venueId: _venueId } = root.$route.params;
            form.value.venueId = _venueId || venueId.value;
            if (spaceId) {
                const features = await toJSON(getFeatures(spaceId));
                form.value.features = [...features, ...spaceStaticFeatures.value]
                    .map(f => f.feature_id)
                    .reduce((obj, key) => {
                    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
                        return {
                            ...obj,
                            [key]: false
                        };
                    }
                    else {
                        obj[key] = true;
                        return {
                            ...obj
                        };
                    }
                }, {});
                formLoaded.value = cloneDeep(form.value.features);
            }
        };
        watch(() => props.onSave, () => {
            if (props.onSave) {
                dataFetch();
            }
        }, { immediate: true });
        const checkErrors = () => {
            return [];
        };
        const { dataLoaded, callAPIAction } = useAsyncPage();
        const mainAction = async () => {
            emit('clicked');
            globalThis.$store.commit('$_vendor/SET_SPACE_DETAILS', form.value);
            isSaving.value = true;
            if (root.$route.params.spaceId) {
                // TODO: implement update space logic
            }
            else {
                try {
                    const spaceResponse = await globalThis.$store.dispatch('$_vendor/createSpace');
                    if (spaceResponse) {
                        globalThis.$router.push({
                            path: lnManager.storageLanguage !== 'undefined' &&
                                globalThis.$i18n.locale !== 'nl'
                                ? `/${globalThis.$i18n.locale}/${ROUTE_LIST_NAMES.SUCCESS.SPACE_CREATED}`
                                : `/${ROUTE_LIST_NAMES.SUCCESS.SPACE_CREATED}`
                        });
                    }
                }
                catch (err) {
                    const toastText = root.$i18n.t(`common.errors.${err.status === 500
                        ? 'something_wrong'
                        : 'onboarding.mandatory_fields'}`);
                    toast.error(toastText);
                    console.log(err);
                }
            }
            isSaving.value = false;
        };
        watch(() => form.value.features, () => {
            if (formLoaded.value && form.value.features) {
                emit('settingsChanged', {
                    page: 'features',
                    data: form.value.features,
                    prevData: formLoaded.value
                });
            }
        }, { deep: true, immediate: true });
        onMounted(async () => {
            const venueIdFromStorage = globalThis.$store.getters['$_vendor/venueId'] ||
                localStorage.getItem('createdVenueId');
            venueId.value = venueIdFromStorage ? parseInt(venueIdFromStorage) : null;
            form.value = { features: {} };
            await callAPIAction('$_vendor/loadSpaceFromStorage');
            await callAPIAction('$_vendor/loadSpaceStaticFeatures');
            await dataFetch();
        });
        return {
            dataLoaded,
            form,
            isSaving,
            spaceStaticFeatures,
            checkErrors,
            mainAction
        };
    }
});
